<template>
  <div>
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false"  />

      <!-- список дат -->
      <div class="tabs__caption--date">
        <div class="icon icon-arrow-right" @click="changeDate(1, 'prev')"></div>
        <ul class="tabs__caption">
          <li 
            v-for="(period_item, index) in period" 
            :key="index"
            :data-date="period_item.date"
            @click="changeDate(index+1, period_item.date_not_format)" 
            v-bind:class="[ activetab === index+1 ? 'active' : '' ]">
            <b>{{ period_item.num_dey }}</b>
            <span>{{ period_item.mounth }}</span>
          </li>
        </ul>
        <div class="icon icon-arrow-right" @click="changeDate(1, 'next')" ></div>
      </div>
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>


      <!-- список -->
      <ul class="block-list">
        <router-link 
          tag="li"
          :key="index"
          :to = "{name: (itemCalendar.quote_id ? 'AgreementsCreateId' : 'QuotationsCreateId'), params: {id: itemCalendar.id}}" 
          v-for="(itemCalendar, index) in calendarNew"
          :data-id="itemCalendar.id"
          
        >

          <a style="display: block">
            <s v-if="itemCalendar.item_viewed !== null" :style="{background: itemCalendar.statusColorBG}"></s>
            <i :style="{background: itemCalendar.statusColorBG}"></i>
            <div style="position: relative;z-index:2">

              <h4 style="display:flex;justify-content: space-between;">
                <strong>{{(itemCalendar.quote_id ? $root.langText.directory.orderOne + ' №' + itemCalendar.order_number : $root.langText.directory.quotesOther + ' №' + itemCalendar.number)}}</strong>
              </h4>
              <p style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.input.manager }}</span>
                {{itemCalendar.manager_name ? itemCalendar.manager_name : '-'}}
              </p>

              <p v-if="itemCalendar.transportation_name" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.directory.orderOne }}</span>
                {{ itemCalendar.transportation_name }}
              </p>
              <p v-else-if="calendar.directions[index][0] && calendar.directions[index][0].poo_name && calendar.directions[index][0].fd_name" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.route }}</span>
                {{ calendar.directions[index][0].poo_name  + '-' + calendar.directions[index][0].fd_name }}
              </p>

              <p v-if="calendar.directions[index][0] && (calendar.directions[index][0].quantity != '0' || calendar.directions[index][0].weight != '0')" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.input.quantity + '/' + $root.langText.input.weight}}</span>
                {{ calendar.directions[index][0].quantity + (calendar.directions[index][0].quantity_measurement_unit_name ? calendar.directions[index][0].quantity_measurement_unit_name : '') + ', ' + calendar.directions[index][0].weight + ' кг.' }}
              </p>
              
              <p v-if="itemCalendar.border_crossing_name" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.input.borderCrossing }}</span>
                {{ itemCalendar.border_crossing_name }}
              </p>

              <p style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.input.client }}</span>
                {{itemCalendar.client_name ? itemCalendar.client_name : '-'}}
              </p>

              <p v-if="itemCalendar.main_carrier_name" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.filters.main_carrier }}</span>
                {{itemCalendar.main_carrier_name}}
              </p>

              <p v-if="itemCalendar.truck_name" style="font-weight:500;margin-bottom: 15px">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.input.car }}</span>
                {{itemCalendar.truck_name}}
              </p>
              
              <p v-if="calendar.customer_freight[index].length == 0" style="color:red">Немає фрахту</p>
              <div v-else>
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.customerFreight }}</span>
                <p
                  style="font-weight:500;line-height:0.9;display: inline;"
                  v-for="(item, index2) in calendar.customer_freight[index]"
                  :key="index2"
                >
                  {{index2 > 0 ? '+' : ''}}
                  {{item.currency_name + ' ' +item.sum}}
                  
                </p>
              </div>

              <div v-if="calendar.carrier_freight[index].length > 0">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.carrierFreight }}</span>
                <p
                  style="font-weight:500;line-height:0.9;display: inline;"
                  v-for="(item, index2) in calendar.carrier_freight[index]"
                  :key="index2"
                >
                  {{index2 > 0 ? '+' : ''}}
                  {{item.currency_name + ' ' +item.sum}}
                  
                </p>
              </div>

              <div v-if="itemCalendar.is_overload == 1">
                <span style="display: inline;color: #8d8d8d;font-size: 10px;">{{ $root.langText.carrierFreight }} 2</span>
                <p
                  style="font-weight:500;line-height:0.9;display: inline;"
                  v-for="(item, index2) in calendar.carrier_freight2[index]"
                  :key="index2"
                >
                  {{index2 > 0 ? '+' : ''}}
                  {{item.currency_name + ' ' +item.sum}}
                  
                </p>
              </div>


            </div>
            <div class="icon icon-arrow-right"></div>
          </a>

        </router-link>


        



      </ul>

      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
  </div>
</template>









<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"


export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Планер', info: ''}
    ],
    is_mine: false,

    filter: {
      flag: true,
      mod: 'calendar'
    },
    btnFilter: false,
    calendar: null,
    calendarNew: [],
    arrFilters: '',
    setinerval: '',

    period: [],
    activetab: 1,
    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    //pagination
    params: {
      start: 0, 
      limit: 100,
    },
    quantityList: '',
    listNot: false,
  }),




  async created() {
    //reload filters

    var efe = this.getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f[search]=' + efe['f[search]']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)


    //title
    this.globalTitle[0].title = this.$root.langText.menu.planer;
    this.$root.title = this.globalTitle;


    this.changeDate((new Date()).getDay());
    
  },




  methods: {


    changeDate(day, dateChange = ''){
      this.activetab = day;
      var date = new Date();

      if(dateChange == 'next'){
        date = new Date(this.period[6].date_not_format.setDate(this.period[6].date_not_format.getDate() + 1))
      } else if(dateChange == 'prev'){
        date = new Date(this.period[0].date_not_format.setDate(this.period[0].date_not_format.getDate() - 1))
      } else if(dateChange !== ''){
        date = new Date(dateChange);
      }
      // console.log(d);

      const dayArray = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
      const month = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
  
      function getMonday(d) {
        var d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      }

      for(let i = 0; i < 7; i++){
        let dataNew = getMonday(date).setDate(getMonday(date).getDate() + i);
        
        this.period[i] = {
          date: this.addFiltersDate(new Date(dataNew)),
          date_not_format: (new Date(dataNew)),
          num_dey: new Date(dataNew).getDate(),
          day: dayArray[new Date(dataNew).getDay()],
          mounth: month[new Date(dataNew).getMonth()]
        }
      }
      if(dateChange == 'next' || dateChange == 'prev'){
        date = this.period[0].date_not_format;
      }
      this.reloadFilters('filters', this.addFiltersDate(new Date(date)));

    },

    getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    },



    addFiltersDate(date){
      function addZero(num) {
        if (num >= 0 && num <= 9) {
          return '0' + num;
        } else {
          return num;
        }
      }
      if(!date) {
        date = new Date();
      }
      return addZero(date.getDate()) + '-' + addZero(date.getMonth() + 1) + '-' + addZero(date.getFullYear());
    },  

    //function filtration
    async reloadFilters(mod, date = ''){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + (this.filtersData ? "&" + this.filtersData : '') + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)

      if(date == ''){
        this.arrFilters += '&f[calendar_date_from]=' + this.addFiltersDate();
      } else{
        this.arrFilters += '&f[calendar_date_from]=' + date;
      }

      var arrFilters = await this.$store.dispatch('postFiltersCalendar', {params: this.params, data: this.arrFilters})
      this.calendar = arrFilters.data.data;
      this.calendarNew = arrFilters.data.data.column_total_items

      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }
  },

  beforeDestroy() {
    // clearInterval(this.setinerval)
  },

  
  components: {
    Search, Filters, Pagination, SelectedFilters
  },
  metaInfo(){
    var title = this.$root.langText.directory.order;
    return {
      title: this.$title(title)
    }
  },
}

</script>